import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { LoadingController } from '@ionic/angular';
import { PathwayInfo } from '../../shared/types';


@Injectable({
  providedIn: 'root'
})
export class PathwayInfoService {
  private pathwayCollection: AngularFirestoreCollection<PathwayInfo>;
  private data: PathwayInfo[];

  constructor(
    private db: AngularFirestore,
    private loadingCtrl: LoadingController
  ) {
    this.pathwayCollection = this.db.collection<PathwayInfo>('pathwayData-en');      // TODO: don't hard-code -en?
    console.log('PathwayInfoService: constructor done');
  }

  async loadAllData(fn?: () => void) {
    console.log('pathwayInfoS.loadAllData: top');

    this.data = [];

    const loading = await this.loadingCtrl.create({
      translucent: true,
    });
    await loading.present();
    console.log('pathwayInfoS.loadAllData: calling subscribe');

    const data = this.pathwayCollection.get();
    data.subscribe((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        this.data.push({
          id: Number(doc.id),
          name: doc.data().name,
          subTitle: doc.data().subTitle,
          iconName: doc.data().iconName,
          description: doc.data().description || '',
        });
      });
      console.log('pathwayInfoSrv.loadAllData: data is ', this.data);
      loading.dismiss();
      if (fn) {
        fn();
      }
    });
  }

  getAllPathwayInfo() {
    return this.data;
  }

  updatePathwayData(pathway: PathwayInfo) {
    this.pathwayCollection.doc(`${pathway.id}`).update({
      name: pathway.name,
      subTitle: pathway.subTitle,
      description: pathway.description || '',
    });
  }

  getPathwayNameForId(pathwayId: number) {
    if (this.data.length === 0) {
      return '';
    }
    return this.data.find((d) => (d.id === pathwayId)).name;
  }
}
