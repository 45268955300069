import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { LoadingController } from '@ionic/angular';
import { TrailInfo } from '../../shared/types';


import * as firebase from 'firebase/app';
import 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class TrailInfoService {
  private trailCollection: AngularFirestoreCollection<TrailInfo>;
  private data: TrailInfo[] = [];

  constructor(
    private db: AngularFirestore,
    private loadingCtrl: LoadingController,
  ) {
    this.trailCollection = this.db.collection<TrailInfo>('trailData-en');
    console.log('TrailInfoService: constructor done');
  }

  async loadAllData(fn?: () => void) {
    if (this.data.length !== 0) {
      if (fn) {
        fn();
      }
      return;
    }

    this.data = [];

    const loading = await this.loadingCtrl.create({
      translucent: true,
    });
    await loading.present();
    console.log('trailInfoS.loadAllData: calling subscribe');

    const data = this.trailCollection.get();
    data.subscribe((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        this.data.push({
          id: Number(doc.id),
          pathwayId: Number(doc.data().pathwayId),
          trailName: doc.data().trailName,
          summary: doc.data().summary,
          complexity: doc.data().complexity,
          leadTime: doc.data().leadTime,
          lastModificationTime: doc.data().lastModificationTime,
          // lastModificationTime: (doc.data().lastModificationTime) ? doc.data().lastModificationTime.toDate().toISOString() : 'blank',
          active: doc.data().active,
          introDate: doc.data().introDate,
          // introDate: (doc.data().introDate) ? doc.data().introDate.toDate().toISOString() : 'blank',
          version: (doc.data().version) ? doc.data().version : 1,
        });
      });
      console.log('trailInfoSrv.loadAllData: data is ', this.data);
      loading.dismiss();
      if (fn) {
        fn();
      }
    });
  }

  getAllTrails() {
    return this.data;
  }

  // Called when trail data has changed.
  updateTrailData(trail: TrailInfo) {
    console.log(`Calling updateTrailData: name->${trail.trailName}, `
      + `lastModTime->${firebase.firestore.Timestamp.now()}, introDate->${trail.introDate}}`
      + `, version->${trail.version}`);

    //  the two dates here here are strings; convert them to a Date format in seconds
    //    by removing the milliseconds.  these will then be used in the calls to 
    //    Firestore.Timestamp
    // const introDateSec = new Date(trail.introDate).getTime() / 1000;
    const introDate = new Date(trail.introDate).toISOString();
    // const modDateSec   = new Date(trail.lastModificationTime).getTime() / 1000;
    const modDate   = new Date(trail.lastModificationTime).toISOString();

    console.log(`introDATE: ${introDate}   modDate: ${modDate}`);

    // this.trailCollection.doc(`${trail.id}`).update({
    //   trailName: trail.trailName,
    //   complexity: trail.complexity,
    //   leadTime: trail.leadTime,
    //   summary: trail.summary,
    //   lastModificationTime: new firebase.firestore.Timestamp(modDateSec, 0),
    //   // lastModificationTime: firebase.firestore.Timestamp.now(),
    //   active: trail.active,
    //   introDate: new firebase.firestore.Timestamp(introDateSec, 0),
    //   version: 0, // hardcode for now 
    // });
    //    if (trail.active) {
    this.trailCollection.doc(`${trail.id}`).set({
      pathwayId: Number(trail.pathwayId),
      trailName: trail.trailName,
      complexity: trail.complexity,
      leadTime: trail.leadTime,
      summary: trail.summary,
      // lastModificationTime: new firebase.firestore.Timestamp(modDateSec, 0),
      lastModificationTime: modDate,
      active: trail.active,
      introDate: introDate,
      version: trail.version, // hardcode for now 
    });
    //    } else {
    //      // Not active, which means it may have been active before, so look in
    //      // trailData-en and delete doc if needed.
    //      this.trailCollection.doc(`${trail.id}`).delete();
    //    }
  }

  deleteRowFromDb(trail: TrailInfo, fn: () => void) {
    this.trailCollection.doc(`${trail.id}`).delete();
    if (trail.active) {
      this.trailCollection.doc(`${trail.id}`).delete();
    }
    this.data = [];
    this.loadAllData(fn);
  }

  addRowToDb(trail: TrailInfo, fn: () => void) {
    const introDate = new Date(trail.introDate).toISOString();
    // const modDateSec   = new Date(trail.lastModificationTime).getTime() / 1000;
    const modDate   = new Date(trail.lastModificationTime).toISOString();

    this.trailCollection.doc(`${trail.id}`).set({
      pathwayId: Number(trail.pathwayId),
      trailName: trail.trailName,
      complexity: trail.complexity,
      leadTime: trail.leadTime,
      summary: trail.summary,
      lastModificationTime: modDate,
      active: trail.active,
      introDate: introDate,
      version: trail.version,
    });
    this.data = [];
    this.loadAllData(fn);
  }
}
