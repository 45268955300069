import { Injectable } from '@angular/core';

import { credentials, credentialArray } from '../credentials';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  private currentDbType: string = "test";
  private currentDbName: string;
  private currentDbCredentials: {};

  constructor() { }

  switchDatabase(db: string) {

    // Save the current DB 
    this.currentDbType = db;

    // Find the correct credentials for this type
    for (var i = 0; i < credentialArray.length; i++) {
      if (credentialArray[i].Type === db) {
        this.currentDbCredentials = credentialArray[i].Credentials;
        this.currentDbName = credentialArray[i].Credentials.projectId;
        break;
      }
    }
    firebase.initializeApp(this.currentDbCredentials);
  }

  getCurrentDbName() {
    return this.currentDbName;
  }

  getCurrentDbType() {
    return this.currentDbType;
  }

  async logoutDatabase() {
    firebase.app().delete().then(() => {
      console.log(`Successfully logged out of firebase.`);
    });
  }

}
